import React from 'react';

export const YearTabs = ({ selectedYearRange, yearRanges }) => {
  return (
    <ul className="flex flex-wrap text-lg font-bold text-center py-4 justify-center">
      {yearRanges.map((yearRange) => (
        <li className="mr-2" key={yearRange}>
          {yearRange === selectedYearRange ? (
            <a
              href={`#${yearRange}`}
              className="inline-block py-3 px-4  bg-secondary rounded-lg active"
            >
              {yearRange}
            </a>
          ) : (
            <a
              href={`#${yearRange}`}
              className="inline-block py-3 px-4 rounded-lg text-gray-500 hover:text-secondary"
            >
              {yearRange}
            </a>
          )}
        </li>
      ))}
    </ul>
  );
};
