import React from "react";

export const PageHeading = ({ title, styles }) => {
  return (
    <h1
      className={`${styles} text-4xl md:text-5xl text-center md:text-left font-heading font-extrabold text-gray-900`}
    >
      {title}
    </h1>
  );
};
