import React from 'react';
import { UpdateCard } from './cards/update-card';

export const UpdateSection = ({ updates = [] }) => {
  if (updates == null || updates.length === 0) {
    return (
      <p className="text-center py-8 text-lg font-semibold text-gray-500">
        No updates yet. Stay tuned!
      </p>
    );
  }

  const sortedUpdates = updates.sort((a, b) => {
    return new Date(b.rawDate) - new Date(a.rawDate);
  });

  return (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
      {sortedUpdates.map((post) => (
        <UpdateCard
          title={post.title}
          date={post.dateOfPost}
          blurb={post.summary.summary}
          slug={post.slug}
          key={post.slug}
          author={post.author}
        />
      ))}
    </div>
  );
};
