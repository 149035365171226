import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../../components/layout/layout';
import { PageHeading } from '../../components/layout/page-heading';
import { UpdateSection } from '../../components/update-section';
import { YearTabs } from '../../components/years-tab';
import { PageDescription } from '../../components/layout/page-description';

const ExecutiveBlog = ({ data, location }) => {
  const updatesPage = data.contentfulUpdatesPage;
  const currentYearRange = updatesPage.currentYear.yearRange;

  const updateYears = [updatesPage.currentYear, ...updatesPage.previousYears];
  const updateYearsMap = updateYears.reduce((obj, item) => {
    return { ...obj, [item['yearRange']]: item };
  }, {});

  const [selectedYear, setSelectedYear] = useState(updatesPage.currentYear);

  const yearRanges = [
    currentYearRange,
    ...updatesPage.previousYears.map((prevYear) => prevYear.yearRange),
  ];

  useEffect(() => {
    if (location.hash) {
      setSelectedYear(updateYearsMap[location.hash.split('#')[1]]);
    } else {
      setSelectedYear(updatesPage.currentYear);
    }
  }, [location.hash, updateYearsMap, updatesPage.currentYear]);

  return (
    <Layout title={'Executive Blog'} styles="">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-0">
        <div className="max-w-2xl mx-auto py-8 lg:max-w-none">
          <div className="pb-8 space-y-2 md:space-y-5">
            <PageHeading title={updatesPage.pageTitle} />
            <PageDescription
              description={updatesPage.description.description}
            />

            <YearTabs
              selectedYearRange={selectedYear.yearRange}
              yearRanges={yearRanges}
            />
            <UpdateSection updates={selectedYear.updates} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ExecutiveBlog;

export const query = graphql`
  query {
    contentfulUpdatesPage {
      pageTitle
      description {
        description
      }
      currentYear {
        ...YearUpdates
      }
      previousYears {
        ...YearUpdates
      }
    }
  }
  fragment YearUpdates on ContentfulUpdatesCalendarYear {
    yearRange
    updates {
      id
      slug
      title
      dateOfPost(formatString: "Do MMMM, YYYY")
      rawDate: dateOfPost
      summary {
        summary
      }
      post {
        raw
      }
      author {
        name
        description {
          description
          childMarkdownRemark {
            html
          }
        }
        displayPicture {
          fixed(width: 400) {
            width
            height
            src
            srcSet
          }
        }
      }
    }
  }
`;
