import React from 'react';

export const AuthorCardFooter = ({ author, date }) => {
  return (
    <div className="flex mt-auto justify-between">
      <div>
        <p className="text-sm font-medium text-gray-500">{date}</p>
        <p className="text-sm font-medium text-gray-900">
          By <span className="font-semibold">{author.name}</span>
        </p>
      </div>

      <div className="hidden md:block w-12 h-12 overflow-hidden">
        <img
          className="w-full h-full object-center object-cover rounded-full drop-shadow-md"
          alt={`${author.name}`}
          src={author.displayPicture.fixed.src}
        />
      </div>
    </div>
  );
};
