import { Link } from 'gatsby';
import React from 'react';
import { AuthorCardFooter } from './author/author-card-footer';

export const UpdateCard = ({ title, date, blurb, slug, author }) => (
  <article className="rounded-lg shadow-xl px-6 py-4 bg-primaryLight flex flex-col">
    <h2 className="my-2 text-2xl font-heading font-semibold text-gray-900">
      <Link
        className="text-gray-900 hover:underline"
        aria-label={`Read ${title}`}
        to={slug}
      >
        {title}
      </Link>
    </h2>
    <hr className="my-2 border-t-2 border-primaryPastel" />
    <p className="mb-3 text-lg font-normal text-gray-600">{blurb}</p>
    <div className="text-base font-semibold mt-2 mb-4">
      <Link
        className="text-secondaryDark hover:underline"
        aria-label={`Read ${title}`}
        to={slug}
      >
        Read more →
      </Link>
    </div>
    <AuthorCardFooter author={author} date={date} />
  </article>
);
